import { useEffect, useLayoutEffect, useState } from "react";
import {
    useNavigate,
    useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import ConfirmModal from "../../components/Common/ConfirmModal";
import UploadFilesModal from "../../components/Common/UploadFilesModal";
import NotFound from "../../components/Common/NotFound";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";
import DownloadFileButton from "../../components/Common/DownloadFileButton/index.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRedo,
    faFolder,
    faTrash,
    faFileUpload
} from "@fortawesome/free-solid-svg-icons";
import {
    useGetFilesByPrefixQuery,
    useDeleteFileMutation,
    useDeleteFolderMutation
} from "../../services/mip";
import {
    formatDateAsISO,
    formatFileSizeAsHumanReadable
} from "../../midgard.js";

export default function Files(props) {   
    const navigate = useNavigate();
    const location = useLocation();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("FileManagement") || privilege.includes("All");
    const isAdmin = privilege.includes("Admin") || privilege.includes("All");
    const queryParams = new URLSearchParams(location.search);
    const prefixParam = queryParams.get("prefix");
    const prefix = prefixParam === null || prefixParam === undefined || prefixParam === "/" ? "" : prefixParam;

    const [confirmDeleteFileModalDisplayed, setConfirmDeleteFileModalDisplayed] = useState();
    const [confirmDeleteFolderModalDisplayed, setConfirmDeleteFolderModalDisplayed] = useState();
    const [showUploadFilesModal, setShowUploadFilesModal] = useState(false);
    const [downloadErrorMessage, setDownloadErrorMessage] = useState();
    const [pagingStart, setPagingStart] = useState(0);
    const pageSize = 500;

    const {
        data: filesData,
        error: filesDataError,
        isFetching: filesDataisFetching,
        refetch: refetchFilesData
    } = useGetFilesByPrefixQuery(prefix, { skip: prefix === undefined || prefix === undefined || !isLoggedIn || !hasPrivilege });

    const closeModalAndRefresh = (refresh) => {
        if (refresh) {
            refetchFilesData();
        }
        setShowUploadFilesModal(false);
    };

    const [deleteFile] = useDeleteFileMutation();
    const confirmDeleteFile = async (identifier) => {
        try {
            await deleteFile(identifier).unwrap();
            setConfirmDeleteFileModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [deleteFolder] = useDeleteFolderMutation();
    const confirmDeleteFolder = async (identifier) => {
        try {
            await deleteFolder(identifier).unwrap();
            setConfirmDeleteFolderModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    useEffect(() => {
        document.title = `Files - ${ prefix ? prefix : "/" }`;
    }, [prefix]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    useLayoutEffect(() => {
        if (prefix !== null && prefix !== undefined && Array.isArray(privilege) && privilege.length > 0 && !isAdmin && (prefix === "" || prefix.startsWith("temp/"))) {
            navigate("/files?prefix=sftp/");
        }
    }, [prefix, privilege, isAdmin, navigate]);

    const prefixParts = prefix.split("/");
    const prefixLinks = [];
    if (isAdmin) {
        prefixLinks.push(<div key="root" className="inline"><span className="text-blue-500 hover:text-blue-700 hover:underline hover:cursor-pointer" onClick={() => navigate("/files")}>root</span>/&nbsp;</div>);
    }
    //let currentPrefix = "";
    for (let i = 0; i < prefixParts.length; i++) {
        if (prefixParts[i] !== "") {
            const path = `${ prefixParts.slice(0, i+1).join("/") }/`;
            prefixLinks.push(<div key={path} className="inline"><span className="text-blue-500 hover:text-blue-700 hover:underline hover:cursor-pointer" onClick={() => navigate(`/files?prefix=${ path }`)}>{ prefixParts[i] }</span>/&nbsp;</div>);
        }
    }
  
    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4">
            <div className="mx-auto mb-10 bg-white rounded-lg">
                <div className="text-2xl pb-4 flex">
                    <div className="flex-1 p-1"><FontAwesomeIcon icon={faFolder} /> Files - {prefix ? prefix : "/" } </div>
                </div>
                {
                    filesDataError
                        ? <ErrorTile message={filesDataError} />
                        : filesDataisFetching || !Array.isArray(filesData?.Data)
                            ? <LoadingSpinner text={"Loading Files"} />
                            :   <>
                                    <div className="flex w-full">
                                        <div className="flex-1">
                                            { prefixLinks }
                                        </div>
                                        <div className="flex-evenly">
                                            {(prefix.startsWith("sftp/") || prefix.startsWith("mip/temp")) && <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 mr-2 rounded" type="button" onClick={() => {setShowUploadFilesModal(true);}}><FontAwesomeIcon icon={faFileUpload} /></button>}
                                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded" type="button" onClick={refetchFilesData}><FontAwesomeIcon icon={faRedo} /></button>
                                        </div>
                                    </div>
                                    {
                                        filesData.Data.length === 0
                                            ?   <div className="text-center">No files found.</div>
                                            :   <>
                                                <div className="text-center mt-4">
                                                    <span key="first" className="mr-2">Showing rows {pagingStart+1}-{pagingStart+pageSize < filesData.Data.length ? pagingStart+pageSize : filesData.Data.length} of {filesData.Data.length}.</span>
                                                    {
                                                        filesData.Data.length > pageSize
                                                            ?   <>
                                                                {
                                                                    filesData.Data.length > pageSize && filesData.Data.map((_, index) => (
                                                                        <>
                                                                        {
                                                                            (index === 0 || index % pageSize === 0) && <span key={index} className="cursor-pointer hover:underline text-gray-600 mr-2" onClick={() => {setPagingStart(index);}}>{(index / pageSize)+1}</span>
                                                                        }
                                                                        </>
                                                                    ))
                                                                }
                                                                </>
                                                            : null
                                                    }
                                                </div>
                                                <div className="grid" style={{gridTemplateColumns: "repeat(3, auto) min-content"}}>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Last Modified</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Size</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                                    {
                                                        filesData.Data.slice(pagingStart,pagingStart+pageSize).map((file, index) => (
                                                            <div className="contents group" key={`file-${ index }`}>
                                                                {
                                                                    file.ObjectKey?.endsWith("/")
                                                                        ?   <>
                                                                                <div className="p-2 border col-span-3 group-odd:bg-gray-100">
                                                                                    <div className="inline text-blue-500 hover:text-blue-700 hover:underline hover:cursor-pointer" onClick={() => navigate(`/files?prefix=${ file.ObjectKey }`)}>{ file.ObjectKey.substring(prefix.length) }</div>
                                                                                </div>
                                                                                <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                                                {
                                                                                    isAdmin
                                                                                        ? <button title="Delete" id={`delete-${ file.ObjectKey }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteFolderModalDisplayed(file.ObjectKey)}><FontAwesomeIcon icon={faTrash} /></button>
                                                                                        : null
                                                                                }
                                                                                </div>
                                                                            </>
                                                                        :   <>
                                                                                <div className="p-2 border group-odd:bg-gray-100">{file.ObjectKey.substring(prefix.length)}</div>
                                                                                <div className="p-2 border group-odd:bg-gray-100">{formatDateAsISO(new Date(`${ file.LastModified }`), false)}</div>
                                                                                <div className="p-2 border group-odd:bg-gray-100">{formatFileSizeAsHumanReadable(file.SizeInBytes)}</div>
                                                                                <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                                                    <DownloadFileButton
                                                                                        filename={file.ObjectKey}
                                                                                        setErrorMessage={setDownloadErrorMessage}
                                                                                    />
                                                                                    <button title="Delete" id={`delete-${ file.ObjectKey }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteFileModalDisplayed(file.ObjectKey)}><FontAwesomeIcon icon={faTrash} /></button>
                                                                                </div>
                                                                                {
                                                                                    downloadErrorMessage
                                                                                        ?   <div key={`download-${ file.ObjectKey }-error`} className="col-span-4">
                                                                                                <ErrorTile message={downloadErrorMessage} />
                                                                                            </div>
                                                                                        : null
                                                                                }
                                                                            </>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                </>
                                    }
                                </>
                }
            </div>
            {
                filesData?.Data && Array.isArray(filesData.Data) && confirmDeleteFileModalDisplayed !== undefined
                    ? filesData.Data.map((file, index) => (
                        confirmDeleteFileModalDisplayed === file.ObjectKey
                            ?   <ConfirmModal
                                    key={index}
                                    title={"Are you sure you want to delete this file?"}
                                    submitButtonTitle="Delete"
                                    identifier={file.ObjectKey}
                                    confirmationMessage={`Are you sure you want to delete ${ file.ObjectKey  }?`} 
                                    failureMessage="File failed to delete:"
                                    closeModal={setConfirmDeleteFileModalDisplayed}
                                    confirmFunction={confirmDeleteFile}
                                />
                            : null
                    ))
                    : null
            }
            {
                filesData?.Data && Array.isArray(filesData.Data) && confirmDeleteFolderModalDisplayed !== undefined
                    ? filesData.Data.map((file, index) => (
                        confirmDeleteFolderModalDisplayed === file.ObjectKey
                            ?   <ConfirmModal
                                    key={index}
                                    title={"Are you sure you want to delete this folder?"}
                                    submitButtonTitle="Delete"
                                    identifier={file.ObjectKey}
                                    confirmationMessage={`Are you sure you want to delete ${ file.ObjectKey  } and all of its contents?`} 
                                    failureMessage="File failed to delete:"
                                    closeModal={setConfirmDeleteFolderModalDisplayed}
                                    confirmFunction={confirmDeleteFolder}
                                />
                            : null
                    ))
                    : null
            }
            {
                showUploadFilesModal && <UploadFilesModal
                                            prefix={prefix}
                                            closeModal={closeModalAndRefresh}
                                        />
            }
        </div>
        </>
    );
}