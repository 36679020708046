import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProvisionableItemReviewPageRow from "../ProvisionableItemReviewPageRow";
import { checkConditions, flattenConvertJsonData, getFieldType } from "../../../midgard.js";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorTile from "../../Common/ErrorTile";
import "./provisionable-item-review-page-custom-grid.css";

import { 
    useProvisionProcessMutation,
    useUpdateProcessMutation,
    useProvisionResourceMutation,
    useUpdateResourceMutation
} from "../../../services/mip";

export default function ProvisionableItemReviewPage(props) { 

    const navigate = useNavigate();

    const [submittingToAPI, setSubmittingToAPI] = useState(false);
    const [provisionErrorMessage, setProvisionErrorMessage] = useState(null);

    const formatCalculationObjects = (userEnteredCalculations) => {
        const calculations = [];
        for (const calculation of userEnteredCalculations) {
            const calcObject = {
                MathOperation: calculation.mathOperation
            };
            // round operation only fires AFTER a math operation so if a round operation
            // exists but math doesn't, fake a math operation of "add 0"
            if (calculation.RoundOperation !== "" && calculation.mathOperation === "") {
                calcObject.MathOperation = "Add";
                calcObject.Value = 0;
            }
            if (calculation.roundOperation !== "") {
                calcObject.RoundOperation = calculation.roundOperation;
            }
            if (calculation.source === "Constant") {
                calcObject.Value = parseFloat(calculation.mathOperand);
            } else {
                calcObject.SourceProperty = calculation.source;
            }
            calculations.push(calcObject);
        }
        return calculations;
    };

    const formatStringConcatenationObjects = (userEnteredStringConcatenations) => {
        const concatenations = [];
        for (const concatenation of userEnteredStringConcatenations) {
            if (concatenation.source === "Constant") {
                concatenations.push({
                    Value: concatenation.value
                });
            } else {
                concatenations.push({
                    SourceProperty: concatenation.source
                });
            }
        }
        return concatenations;
    };

    const formatFormatStringFunctions = (userEnteredFormatStringFunctions) => {
        const formatStringOptions = [];
        for (const formatOption of userEnteredFormatStringFunctions) {
            const optionToAdd = {
                FormatType: formatOption.FormatType
            };
            if (["PadRight", "PadLeft"].includes(formatOption.FormatType)) {
                optionToAdd.PaddingChar = formatOption.PaddingChar;
                optionToAdd.TotalWidth = parseInt(formatOption.TotalWidth);
            }
            if (["SplitRight", "SplitLeft"].includes(formatOption.FormatType)) {
                optionToAdd.SplitOn = formatOption.SplitOn;
            }
            if (formatOption.FormatType === "Replace") {
                optionToAdd.OldValue = formatOption.OldValue;
                optionToAdd.NewValue = formatOption.NewValue;
            }
            if (formatOption.FormatType === "Substring") {
                optionToAdd.StartIndex = parseInt(formatOption.StartIndex);
                optionToAdd.Length = formatOption.Length !== "" ? parseInt(formatOption.Length) : null;
            }
            if (formatOption.FormatType === "StripLineBreaks") {
                optionToAdd.ReplacementValue = formatOption.ReplacementValue;
            }
            if (formatOption.FormatType === "JsonSerialize") {
                optionToAdd.IgnoreNullAndDefaultValues = formatOption.IgnoreNullAndDefaultValues;
                optionToAdd.TypeNameHandling = formatOption.TypeNameHandling === "None" ? null : formatOption.TypeNameHandling;
            }
            formatStringOptions.push(optionToAdd);
        }
        return formatStringOptions;
    };

    const formatExportDataSelector = (field, fieldData) => {
        const exportData = {
            $type: "MIP.Provisioning.Forms.Specialized.Export.DataSelectorResponse, Provisioning",
            items: []
        };
        const fileType = props.formState[field.ResultFileTypeProvisioningIdentifier].value;
        const isFlatFile = ["Delimited", "Excel", "FixedWidth"].includes(fileType);
        const flattenChecked = field.FlattenProvisioningIdentifier && props.formState[field.FlattenProvisioningIdentifier].value;
        const mappingData = props.formState[field.Parameter.Identifier].value;
        const externalDataByName = {};
        for (const externalDataItem of props.formState[field.Parameter.Identifier].externalData) {
            externalDataByName[externalDataItem.Name] = externalDataItem;
        }
        if (isFlatFile || flattenChecked) {
            exportData["FlatArraySettings"] = [];
            for (const arrayIdentifier of Object.keys(mappingData.arrayData)) {
                exportData["FlatArraySettings"].push({
                    NumberOfColumns: mappingData.arrayData[arrayIdentifier].VerticalOrHorizontal === "horizontal" ? mappingData.arrayData[arrayIdentifier].NumberOfColumns : null,
                    PropertyPath: arrayIdentifier
                });
            }
        }
        for (const item of mappingData.fields) {
            const objectToAdd = {};
            if (item.source === "Entity") {
                objectToAdd["$type"] = "MIP.Provisioning.Forms.Specialized.Export.SourcedDataSelectorResponseItem, Provisioning";
                objectToAdd["CustomPropertyName"] = externalDataByName[item.entityProperty].PropertyKeyOptions ? item.customPropertyKey : null;
                objectToAdd["SourceProperty"] = item.entityProperty;
                objectToAdd["DestinationPropertyOverride"] = item.destinationPropertyOverride !== "" ? item.destinationPropertyOverride : null;
            } else {
                objectToAdd["$type"] = "MIP.Provisioning.Forms.Specialized.Export.AdditionalDataSelectorResponseItem, Provisioning";
                objectToAdd["ConstantValue"] = item.constantValue;
                objectToAdd["DestinationProperty"] = item.destinationProperty !== "" ? item.destinationProperty : null;
            }
            if (item.formatOption !== "None") {
                const typeCode = item.source === "Entity" ? externalDataByName[item.entityProperty].JsonType : item.formatTypeCode;
                const formatObject = {
                    FormatOption: item.formatOption,
                    TypeCode: typeCode,
                };
                // TypeCode Boolean, Has no additional options
                if (typeCode === "DateTime") {
                    formatObject.SourceTimeZoneId = item.sourceTimeZoneId;
                    formatObject.DestinationTimeZoneId = item.destinationTimeZoneId;
                    if (["Format", "ParseThenFormat"].includes(item.formatOption)) {
                        formatObject.FormatDateTimeFormatString = item.formatString;
                    }
                    if (["Parse", "ParseThenFormat"].includes(item.formatOption)) {
                        formatObject.ParseDateTimeFormatString = item.parseString;
                    }
                } else if (typeCode === "Double" && ["Format", "ParseThenFormat"].includes(item.formatOption)) {
                    // Double
                    formatObject.FormatDoubleFormatString = item.formatString;
                } else if (typeCode === "String" && ["Format", "ParseThenFormat"].includes(item.formatOption)) {
                    formatObject.FormatStringOptions = formatFormatStringFunctions(item.formatStringFunctions);
                }
                objectToAdd["Format"] = formatObject;
            }
            if (item.useDataMaps) {
                objectToAdd["DataMapName"] = item.dataMapName;
            }
            if (item.applyStringConcatenations) {
                const concatenations = formatStringConcatenationObjects(item.concatenations);
                if (concatenations.length > 0) {
                    objectToAdd["StringConcatenations"] = concatenations;
                }
            }
            if (item.applyCalculation) {
                const calculations = formatCalculationObjects(item.calculations);
                if (calculations.length > 0) {
                    objectToAdd["Calculations"] = calculations;
                }
            }
            exportData.items.push(objectToAdd);
        }
        return JSON.stringify(exportData);
    };

    const formatDataSelector = (field, fieldData, fieldType) => {
        // values stores the array of stuff to send.
        const values = [];
        let allColumnHeaderNames = [];
        if (props.formState[field.FileLayoutSelectorProvisioningIdentifier].externalData.length > 0) {
            const flattenedData = flattenConvertJsonData(null, props.formState[field.FileLayoutSelectorProvisioningIdentifier].externalData[0]);
            allColumnHeaderNames = Object.keys(flattenedData);
        }

        // mappedColumns stores what columns are mapped so we dont pass them along as unmapped later
        const mappedColumns = []; 
        for (const property of props.formState[field.Parameter.Identifier].value) {
            if (property.displayIndex !== -1 && Array.isArray(property.userEnteredData) && property.userEnteredData.length > 0) {
                for (const userEnteredData of property.userEnteredData) {
                    let valueObject = {
                        $type: fieldType === "DataSelector" ? "MIP.Provisioning.Forms.Specialized.MappedDataSelectorResponseItem, Provisioning" : "MIP.Provisioning.Forms.Specialized.Import.MappedDataSelectorResponseItem, Provisioning",
                        DestinationProperty: property.Name
                    };
                    if (property.PropertyKeyOptions && userEnteredData.customPropertyKey !== "") {
                        valueObject["CustomPropertyName"] = userEnteredData.customPropertyKey;
                    }
                    if (["Constant", "DataFile"].includes(userEnteredData.source)) {
                        if (userEnteredData.source === "DataFile") {
                            if (allColumnHeaderNames.length > parseInt(userEnteredData.dataFileLocation)) {
                                valueObject["SourceProperty"] = allColumnHeaderNames[parseInt(userEnteredData.dataFileLocation)];
                                mappedColumns.push(allColumnHeaderNames[parseInt(userEnteredData.dataFileLocation)]);
                            }
                        }
                        if (userEnteredData.source === "Constant") {
                            valueObject["$type"] = fieldType === "DataSelector" ? "MIP.Provisioning.Forms.Specialized.AdditionalDataSelectorResponseItem, Provisioning" : "MIP.Provisioning.Forms.Specialized.Import.AdditionalDataSelectorResponseItem, Provisioning";
                            if (Array.isArray(userEnteredData.constantValue)) {
                                valueObject["ConstantValue"] = userEnteredData.constantValue.join(",");
                            } else {
                                valueObject["ConstantValue"] = userEnteredData.constantValue;
                            }
                        } 
                        if (userEnteredData.lookupEntityKeyByIdentifier === true) {
                            valueObject["Lookup"] = true;
                            if (userEnteredData.lookupCacheFilename !== "") {
                                valueObject["LookupCacheFilename"] = userEnteredData.lookupCacheFilename;
                            }
                            if (userEnteredData.useLookupEntityKeyFallback) {
                                valueObject["LookupEntityKeyFallback"] = userEnteredData.lookupEntityKeyFallback;
                            }
                        }
                        if (userEnteredData.formatOption !== "None") {
                            const typeCode = userEnteredData.formatTypeCode;
                            const formatObject = {
                                FormatOption: userEnteredData.formatOption,
                                TypeCode: userEnteredData.formatTypeCode,
                            };
                            // TypeCode Boolean Has no additional options
                            if (typeCode === "DateTime") {
                                formatObject.SourceTimeZoneId = userEnteredData.sourceTimeZoneId;
                                formatObject.DestinationTimeZoneId = userEnteredData.destinationTimeZoneId;
                                if (["Format", "ParseThenFormat"].includes(userEnteredData.formatOption)) {
                                    formatObject.FormatDateTimeFormatString = userEnteredData.formatString;
                                }
                                if (["Parse", "ParseThenFormat"].includes(userEnteredData.formatOption)) {
                                    formatObject.ParseDateTimeFormatString = userEnteredData.parseString;
                                }
                            } else if (typeCode === "Double" && ["Format", "ParseThenFormat"].includes(userEnteredData.formatOption)) {
                                // Double
                                formatObject.FormatDoubleFormatString = userEnteredData.formatString;
                            } else if (typeCode === "String" && ["Format", "ParseThenFormat"].includes(userEnteredData.formatOption)) {
                                formatObject.FormatStringOptions = formatFormatStringFunctions(userEnteredData.formatStringFunctions);
                            }
                            valueObject["Format"] = formatObject;
                        }
                        if (userEnteredData.useDataMaps) {
                            valueObject["DataMapName"] = userEnteredData.dataMapName;
                        }
                        if (userEnteredData.applyStringConcatenations) {
                            const concatenations = formatStringConcatenationObjects(userEnteredData.concatenations);
                            if (concatenations.length > 0) {
                                valueObject["StringConcatenations"] = concatenations;
                            }
                        }
                        if (userEnteredData.applyCalculation) {
                            const calculations = formatCalculationObjects(userEnteredData.calculations);
                            if (calculations.length > 0) {
                                valueObject["Calculations"] = calculations;
                            }
                        }
                        if (valueObject.SourceProperty && valueObject.DestinationProperty && ((valueObject.SourceProperty.split("[]").length > valueObject.DestinationProperty.split("[]").length) || (valueObject.SourceProperty.endsWith("[]") && !valueObject.DestinationProperty.endsWith("[]")))) {
                            valueObject["ConcatenateDelimiter"] = userEnteredData.concatenateDelimiter;
                        }
                        if (userEnteredData.urlSubstitution) {
                            let keyLength = null;
                            if (Number.isInteger(parseInt(userEnteredData.urlSubstitutionKeyLength))) {
                                keyLength = parseInt(userEnteredData.urlSubstitutionKeyLength);
                            }
                            let ttl = 24;
                            if (!Number.isNaN(parseFloat(userEnteredData.urlSubstitutionTTL))) {
                                ttl = parseFloat(userEnteredData.urlSubstitutionTTL);
                            }
                            valueObject["UrlSubstitution"] = {
                                TTLHours: ttl,
                                KeyLength: keyLength,
                            };
                        }
                    }
                    if (userEnteredData.source === "RegionDefault") {
                        valueObject = null;
                    }
                    if (valueObject !== null) {
                        values.push(valueObject);
                    }
                }
            }
        }

        for (const column of allColumnHeaderNames) {
            if (!mappedColumns.includes(column)) {
                values.push({
                    $type: fieldType === "DataSelector" ? "MIP.Provisioning.Forms.Specialized.UnmappedDataSelectorResponseItem, Provisioning" : "MIP.Provisioning.Forms.Specialized.Import.UnmappedDataSelectorResponseItem, Provisioning",
                    SourceProperty: column
                });
            }
        }
        return JSON.stringify({
            $type: fieldType === "DataSelector" ? "MIP.Provisioning.Forms.Specialized.DataSelectorResponse, Provisioning" : "MIP.Provisioning.Forms.Specialized.Import.DataSelectorResponse, Provisioning",
            $values: values
        });
    };

    const formatFileLayoutSelector = (field, fieldData) => {
        let columnNames = [];
        if (props.formState[field.Parameter.Identifier].externalData.length > 0) {
            const flattenedData = flattenConvertJsonData(null, props.formState[field.Parameter.Identifier].externalData[0]);
            columnNames = Object.keys(flattenedData);
        }
        // props.formState[field.Parameter.Identifier].value should be a FileList object
        const fileNames = [];
        for (const file of props.formState[field.Parameter.Identifier].value) {
            fileNames.push(file.name);
        }
        return JSON.stringify({
            Filename: fileNames.length > 0 ? fileNames[0] : null,
            ColumnNames: columnNames
        });
    };

    const formatFixedWidthColumnsSelector = (field, fieldData) => {
        const values = [];
        // data comes across already in the right object format so we just need to add the
        // type column
        for (const column of fieldData.value) {
            values.push({
                ...column,
                ...{
                    $type: "MIP.FixedWidthColumn, MIP"
                }
            });
        }
        return JSON.stringify(values);
    };
    

    // const escapeDelimiter = (str, delimiter = ",") => {
    //     return (str !== null && str !== undefined) && (str.includes('"') || str.includes(delimiter) || str.includes('\r') || str.includes('\n'))
    //         ? '"' + str.replaceAll('"', '""') + '"'
    //         : str
    // }

    // const arrayToDelimitedString = (arr, delimiter = ",") => {
    //     const newArray = [];
    //     for (const str of arr) {
    //         newArray.push(escapeDelimiter(str, delimiter));
    //     }
    //     return newArray.join(delimiter);
    // }

    const getSubmitValue = (field) => {
        const fieldData = props.formState[field.Parameter.Identifier];
        const fieldType = getFieldType(field);
        let formValue = "";
        if (fieldType === "Export.DataSelector") {
            formValue = formatExportDataSelector(field, fieldData);
        } else if (["DataSelector", "Import.DataSelector"].includes(fieldType)) {
            formValue = formatDataSelector(field, fieldData, fieldType);
        } else if (fieldType === "FileLayoutSelector") {
            formValue = formatFileLayoutSelector(field, fieldData);
        } else if (fieldType === "FixedWidthColumnsSelector") {
            formValue = formatFixedWidthColumnsSelector(field, fieldData);
        } else if (fieldType === "FileSelectorField") {
            // props.formState[field.Parameter.Identifier].value should be a FileList object
            const fileNames = [];
            for (const file of props.formState[field.Parameter.Identifier].value) {
                fileNames.push(file.name);
            }
            formValue = fileNames.length > 0 ? fileNames[0] : "";
        } else if (fieldData !== null && fieldData !== undefined) {
            // All other, defined settings.
            if (Array.isArray(fieldData.value) && field.Parameter.ArrayDelimiter) {
                // array.toString() will return a CSV list
                // but we want to be able to use custom delimeters
                formValue = fieldData.value.join(field.Parameter.ArrayDelimiter);
            } else {
                formValue = fieldData.value.toString();
            }
        }
        return {
            ParameterIdentifier: field.Parameter.Identifier,
            Value: formValue
        };
    };

    const getSubmitParameters = () => {
        const parameters = [];
        for (const form of props.forms) {
            const formConditionMet = checkConditions(form.Conditions, props.formState);
            if (formConditionMet) {
                for (const field of form.Fields) {
                    const fieldConditionMet = checkConditions(field.Conditions, props.formState);
                    if (fieldConditionMet) {
                        if (!field.Parameter.IgnoredOnPost) {
                            parameters.push(getSubmitValue(field));
                        }
                    }
                }
            }
        }
        return parameters;
    };

    const [provisionProcess] = useProvisionProcessMutation();
    const [provisionResource] = useProvisionResourceMutation();
    const handlePostSubmit = async () => {
        setSubmittingToAPI(true);
        const dataToPost = {
            ItemIdentifier: props.postItemIdentifier,
            Values: getSubmitParameters()
        };
        process.env.REACT_APP_ENV === "test" && console.log("dataToPost", dataToPost);
        try {
            if (props.provisionableItemType === "process") {
                await provisionProcess(dataToPost).unwrap();
                navigate("/processes");
            } else {
                // props.provisionableItemType === resource
                await provisionResource(dataToPost).unwrap();
                navigate("/resources");
            }
            window.scrollTo(0,0);
        } catch (err) {
            setProvisionErrorMessage(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
            setSubmittingToAPI(false);
        }
    };

    const handleCloseButton = () => {
        if (props.provisionableItemType === "process") {
            navigate("/processes");
        } else {
            // props.provisionableItemType === resource
            navigate("/resources");
        }
        window.scrollTo(0,0);
    };

    const [updateProcess] = useUpdateProcessMutation();
    const [updateResource] = useUpdateResourceMutation();
    const handlePutSubmit = async (e) => {
        setSubmittingToAPI(true);

        const dataToPost = {
            ItemIdentifier: props.putItemIdentifier,
            Values: getSubmitParameters()
        };
        process.env.REACT_APP_ENV === "test" && console.log("dataToPut", dataToPost);
        try {
            if (props.provisionableItemType === "process") {
                await updateProcess({id: props.putItemIdentifier, body: dataToPost}).unwrap();
                navigate("/processes");
            } else {
                // props.provisionableItemType === resource
                await updateResource({id: props.putItemIdentifier, body: dataToPost}).unwrap();
                navigate("/resources");
            }
            window.scrollTo(0,0);
        } catch (err) {
            setProvisionErrorMessage(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
            setSubmittingToAPI(false);
        }
    };

    // in the future we will have a "hide from review page" or similar setting
    // in the meantime we fake it.
    const fieldTypesToNotDisplay = ["FileLayoutSelector"];

    const fieldsToReview = [];
    if (props.provisionMode === "view") {
        fieldsToReview.push(
            <div className="contents" key="ProcessmName">
                <div className="text-2xl mb-2 hidden print:block align-top text-left col-span-2">{props.provisionableItem.Name}</div>
            </div>
        );
        fieldsToReview.push(
            <div className="contents provisionable-item-review-page-custom-grid-row" key="ProvisionableItemName">
                <div className="p-2 border font-bold align-top text-left capitalize">Source {props.provisionableItemType}</div>
                <div className="p-2 border">{props.availableProvisionableItemWhenEditing.Name}</div>
            </div>
        );
    }
    for (const form of props.forms) {
        const formConditionMet = checkConditions(form.Conditions, props.formState);
        if (formConditionMet) {
            for (const field of form.Fields) {
                const fieldType = getFieldType(field);
                const skipField = fieldTypesToNotDisplay.includes(fieldType) || !checkConditions(field.Conditions, props.formState) || field.Parameter.IgnoredOnPost;
                if (!skipField) {
                    fieldsToReview.push(
                        <ProvisionableItemReviewPageRow
                            key={field.Parameter.Identifier}
                            field={field}
                            formState={props.formState}
                            provisionMode={props.provisionMode}
                            identifierToExcludeFromLinkedQuery={props.identifierToExcludeFromLinkedQuery}
                        />
                    );
                }
            }
        }
    }

    return (
        <>
        {
            props.provisionMode !== "view"
                ? <div className="print:hidden font-bold">Please review the data to be submitted.</div>
                : null
        }
        <div className="grid gap-0 provisionable-item-review-page-custom-grid">
            {fieldsToReview}
            {
                props.provisionMode === "view" && props.provisionableItem.DisplayProperties !== undefined && props.provisionableItem.DisplayProperties !== null
                    ?   Object.keys(props.provisionableItem.DisplayProperties).map((key, index) => 
                            <>
                                {
                                    key !== "Notes" && <div key={`DisplayProperties-${ index }`} className="contents provisionable-item-review-page-custom-grid-row">
                                            <div className="p-2 border font-bold align-top text-left">{key}</div>
                                            <div className="p-2 border">{props.provisionableItem.DisplayProperties[key]}</div>
                                        </div>
                                }
                            </>
                        )
                    : null
            }
        </div>
        {
            provisionErrorMessage 
                ? <ErrorTile message={provisionErrorMessage} />   
                : null
        }
        <div className="mt-4 print:hidden">
        {
            submittingToAPI
                ?   <LoadingSpinner text="Provisioning" />
                :   <div className="flex justify-evenly">
                        <button onClick={() => handleCloseButton()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">{props.provisionMode !== "view" ? "Cancel" : "Close"}</button>
                        {
                            props.provisionMode !== "view"
                                ? <button onClick={props.onPreviousButtonClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">Back</button>
                                : null
                        }
                        {
                            props.provisionMode === "new" || (props.provisionMode === "edit" && !props.singleInstanceOnly)
                                ? <button data-test-id='provision-post-button' onClick={handlePostSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">{props.provisionMode === "edit" ? "Provision As New" : "Provision"}</button>
                                : null
                        }
                        {
                            props.provisionMode === "edit" && props.isDeployed !== null && props.isDeployed === false
                                ? <button data-test-id='provision-put-button' onClick={handlePutSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">Update</button>
                                : null
                        }
                    </div>
        }
        </div>
        </>
    );
}