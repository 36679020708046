import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import AdminLeftNav from "../../../components/Admin/AdminLeftNav";
import ErrorTile from "../../../components/Common/ErrorTile";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import NotFound from "../../../components/Common/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faWarning, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
    useGetProfileQuery,
    useGetCustomersQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation
} from "../../../services/mip";

export default function ApiKeysForm(props) {   
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Admin") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const newMode = params.username.toLowerCase() === "new";
    
    const { 
        data: customerData,
        error: customerError,
        isFetching: customerIsFetching
    } = useGetCustomersQuery(undefined, { skip: !isLoggedIn || !hasPrivilege});

    const { 
        data: profileData,
        error: profileError,
        isFetching: profileIsFetching
    } = useGetProfileQuery(undefined, { skip: !isLoggedIn || !hasPrivilege});

    const { 
        data: userData,
        error: userError,
        isFetching: userIsFetching
    } = useGetUserQuery(params.username, { skip: !isLoggedIn || !hasPrivilege || newMode});

    const [email, setEmail] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedPrivileges, setSelectedPrivileges] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [validationError, setValidationError] = useState();
    const [apiError, setApiError] = useState();
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    // TODO: Add Max's Additional Info Notes Here
    //       and make them visible in the form.
    const possiblePrivileges = useRef({
        "ProvisionProcesses": {
            "Label": "Provision Processes",
            "AdditionalInfo": ""
        },
        "ProvisionResources": {
            "Label": "Provision Resources",
            "AdditionalInfo": "Must also set ProvisionProcesses (to have sufficient API access)"
        },
        "Deploy": {
            "Label": "Deploy",
            "AdditionalInfo": "Must also set ProvisionProcesses & ProvisionResources (to have sufficient API access)"
        },
        "PublicApi": {
            "Label": "Public API",
            "AdditionalInfo": "Typically set without any other permissions"
        },
        "ManageCustomers": {
            "Label": "Manage Customers",
            "AdditionalInfo": "Must also set ProvisionProcesses (to do anything functional with this permission)"
        },
        "CreateCustomer": {
            "Label": "Create Customer",
            "AdditionalInfo": "Must also set ManageCustomers (to get to the Customers tab on the Profile page)"
        },
        "Admin": {
            "Label": "Admin",
            "AdditionalInfo": "Should be set with All"
        },
        "Logs": {
            "Label": "View Logs",
            "AdditionalInfo": ""
        },
        "UrlSubstitutions": {
            "Label": "Manage URL Substitutions",
            "AdditionalInfo": ""
        },
        "FileManagement": {
            "Label": "Manage Files",
            "AdditionalInfo": ""
        },
        "Tools": {
            "Label": "Tools",
            "AdditionalInfo": ""
        },
        "ManualExecute": {
            "Label": "Manually Execute Processes",
            "AdditionalInfo": ""
        }
    });

    useLayoutEffect(() => {
        if (!newMode && userData?.Data) {
            setSelectedCustomer(userData?.Data?.Profile?.CustomerIdentifier ?? undefined);

            let privilegesFromData = [];
            if (userData?.Data?.Profile?.Privilege) {
                if (userData.Data.Profile.Privilege === "All") {
                    privilegesFromData = Object.keys(possiblePrivileges.current);
                } else {
                    privilegesFromData = userData.Data.Profile.Privilege.split(",").map((s) => s.trim());
                }
            }
            setSelectedPrivileges(privilegesFromData);
            
            const groupsFromData = [];
            if (Array.isArray(userData?.Data?.Profile?.CustomerGroups)) {
                for (const group of userData.Data.Profile.CustomerGroups) {
                    groupsFromData.push(group.Value);
                }
            }
            setSelectedGroups(groupsFromData);
        }
    }, [newMode, userData, possiblePrivileges]);

    const formValidation = () => {
        setValidationError(undefined);
        const validationErrors = [];
        if (selectedPrivileges.length === 0) {
            validationErrors.push("Please select at least one privilege.");
        }
        if (selectedGroups.length === 0) {
            validationErrors.push("Please select at least one group.");
        }
        if (validationErrors.length !== 0) {
            setValidationError(validationErrors.join("; "));
            return false;
        }
        return true;
    };

    const [createUserFunction] = useCreateUserMutation();
    const createUser = async () => {
        setSubmittingToAPI(true);
        setApiError(undefined);
        if (formValidation()) {
            const objectToPost = {
                CustomerGroups: selectedGroups,
                CustomerIdentifier: selectedCustomer,
                Privilege: selectedPrivileges.join(","),
                Username: email
            };
            try {
                await createUserFunction(objectToPost).unwrap();
                navigate("/admin/users");
            } catch (err) {
                if (err.data?.message) {
                    setApiError(err.data.message);
                } else if (err.data?.Message) {
                    setApiError(err.data.Message);
                } else if (err.value?.error?.data?.Message) {
                    setApiError(err.value.error.data.Message);
                } else {
                    setApiError(err.toString());
                }
            }
        }
        setSubmittingToAPI(false);
    };

    const [updateUserMutation] = useUpdateUserMutation();
    const updateUser = async () => {
        setSubmittingToAPI(true);
        setApiError(undefined);
        if (formValidation()) {
            const objectToPost = {
                username: params.username,
                body: {
                    CustomerGroups: selectedGroups,
                    CustomerIdentifier: selectedCustomer,
                    Privilege: selectedPrivileges.join(",")
                }
            };
            try {
                await updateUserMutation(objectToPost).unwrap();
                navigate("/admin/users");
            } catch (err) {
                console.log(err);
                if (err.data?.message) {
                    setApiError(err.data.message);
                } else if (err.data?.Message) {
                    setApiError(err.data.Message);
                } else if (err.value?.error?.data?.Message) {
                    setApiError(err.value.error.data.Message);
                } else {
                    setApiError(err.toString());
                }
            }
        }
        setSubmittingToAPI(false);
    };

    const pageTitle = newMode
        ? "Create User"
        : `Edit User ${ params.username }`;

    useEffect(() => {
        document.title = `Administration - ${  pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (userError && userError.status === 401) {
            window.location.href="/login";
        }
    }, [userError]);

    const possibleGroups = {};
    if (Array.isArray(profileData?.Data?.AllCustomerGroups)) {
        for (const possibleGroup of profileData.Data.AllCustomerGroups) {
            possibleGroups[possibleGroup.Key] = possibleGroup.Value;
        }
    }

    const handleGroupSelectionChange = (e) => {
        const selectedOptions = [...selectedGroups];
        if (e.target.checked) {
            selectedOptions.push(e.target.value);
        } else {
            const currentIndex = selectedOptions.indexOf(e.target.value);
            selectedOptions.splice(currentIndex, 1);
        }
        setSelectedGroups(selectedOptions);
    };

    const handlePrivilegeSelectionChange = (e) => {
        const selectedOptions = [...selectedPrivileges];
        if (e.target.checked) {
            selectedOptions.push(e.target.value);
        } else {
            const currentIndex = selectedOptions.indexOf(e.target.value);
            selectedOptions.splice(currentIndex, 1);
        }
        setSelectedPrivileges(selectedOptions);
    };

    const somethingIsFetching = userIsFetching || customerIsFetching || profileIsFetching;
    const firstError = userError || customerError || profileError;

    if (!newMode && userError && Array.isArray(userError?.Data)) {
        return <NotFound message="User Not Found" />;
    }

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        < UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <AdminLeftNav selected="users" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                {
                    firstError
                        ? <ErrorTile message={firstError} />
                        : somethingIsFetching
                            ?   <LoadingSpinner text={"Loading Data"} />
                            :   <>
                                    <div className="text-2xl p-1 pb-4">
                                        <FontAwesomeIcon icon={faUsers} /> {pageTitle}
                                    </div>
                                    {
                                        apiError && <ErrorTile message={apiError} />
                                    }
                                    <div>
                                        {
                                            newMode
                                                ?   <div className="my-3">
                                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="email">
                                                            Email
                                                        </label>
                                                        <input type="text" id="email" name="email" value={email ?? ""} onChange={(e) => setEmail(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                    </div>
                                                : null
                                        }
                                         <div className="my-3">
                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="selectedCustomer">
                                                Customer
                                            </label>
                                            {
                                                Array.isArray(customerData.Data)
                                                    ?   <select id="selectedCustomer" name="selectedCustomer" value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                            {
                                                                [...customerData.Data].sort((a,b) => (a.Name > b.Name ? 1 : ((a.Name < b.Name) ? -1 : 0))).map((customer, index) => (
                                                                    <option key={index} value={customer.Identifier}>{customer.Name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    : null
                                            }
                                        </div>
                                        <div className="my-3">
                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="selectedGroups">
                                                Groups
                                            </label>
                                            {
                                                Object.keys(possibleGroups).map((key, index) => (
                                                    <div key={index} className="block ml-4">
                                                        <label>
                                                            <input type="checkbox" name="selectedGroups" value={possibleGroups[key]} checked={selectedGroups.includes(possibleGroups[key])} className="align-middle" onChange={handleGroupSelectionChange}/>
                                                            <span className="text-grey-800 text-sm mb-2 ml-2 align-middle">{key}</span>
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="my-3">
                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="selectedPrivileges">
                                                Privileges
                                            </label>
                                            {
                                                Object.keys(possiblePrivileges.current).map((privelege, index) => (
                                                    <div key={index} className="block ml-4">
                                                        <label>
                                                            <input type="checkbox" name="selectedPrivileges" value={privelege} checked={selectedPrivileges.includes(privelege)} className="align-middle" onChange={handlePrivilegeSelectionChange}/>
                                                            <span className="text-grey-800 text-sm mb-2 ml-2 align-middle">{possiblePrivileges.current[privelege].Label}{possiblePrivileges.current[privelege].AdditionalInfo !== "" ? ` - ${ possiblePrivileges.current[privelege].AdditionalInfo }` : null}</span>
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            validationError && <div className="text-orange-400 text-center mb-2"><FontAwesomeIcon icon={faWarning} /> {validationError}</div>
                                        }
                                        <div className="flex justify-evenly">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/admin/users")}>Back to Users</button>
                                            {
                                                newMode
                                                    ? <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={createUser}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add User"}</button>
                                                    : <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={updateUser}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Update User"}</button>
                                            }
                                        </div>
                                    </div>
                                </>
                    }
                </div>
            </div>
        </div>
        </>
    );
}