import { Auth } from "aws-amplify";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { getAdminLinks, getSettingsLinks } from "../../../midgard";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import baxterLogo from "./baxter-long-black-logo.png";
import soleraLogo from "./solera-long-black-logo.jpg";

export default function Menu(props) {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const userIsAdmin = privilege.includes("ManageCustomers") || privilege.includes("Admin") || privilege.includes("All");
    const userHasLogsAccess = privilege.includes("Logs") || privilege.includes("All");
    const userHasFilesAccess = privilege.includes("FileManagement") || privilege.includes("All");
    const userCanProvisionProcesses = privilege.includes("ProvisionProcesses") || privilege.includes("All");
    const userCanProvisionResources = privilege.includes("ProvisionResources") || privilege.includes("All");
    const userCanExecuteManualProcesses = privilege.includes("ManualExecute") || privilege.includes("Deploy") || privilege.includes("All");
    const [showMenu, setShowMenu] = useState(false);
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const menuRef = useRef(null);

    const settingLinks = getSettingsLinks(privilege);
    const adminLinks = getAdminLinks(privilege);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowAccountMenu(false);
                document.getElementById("accountDropDown").style.display = "none";
                if (document.getElementById("navMenu") !== null) {
                    setShowMenu(false);
                    const md = window.matchMedia("(min-width: 768px)").matches;
                    if (!md) {
                        document.getElementById("navMenu").style.display = "none";
                    }
                }
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);
    
    const toggleAccountMenu = () => {
        const newShowAccountMenu = !showAccountMenu;
        setShowAccountMenu(newShowAccountMenu);
        if (newShowAccountMenu) {
            document.getElementById("accountDropDown").style.display = "block";
        } else {
            document.getElementById("accountDropDown").style.display = "none";
        }
    };

    const toggleMenu = () => {
        const md = window.matchMedia("(min-width: 768px)").matches;
        if (document.getElementById("navMenu") !== null) {
            const newShowMenu = !showMenu;
            setShowMenu(newShowMenu);
            if (newShowMenu) {
                document.getElementById("navMenu").style.display = "block";
            } else if (!md) {
                document.getElementById("navMenu").style.display = "none";
            }
        }
    };

    const handleSignOutButton = async (event) => {
        event.preventDefault();
        try {
            await Auth.signOut();
            window.location.href = "/login";
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (document.getElementById("navMenu") !== null) {
                const md = window.matchMedia("(min-width: 768px)").matches;
                if (md) {
                    setShowMenu(true);
                    document.getElementById("navMenu").style.display = "block";
                } else {
                    setShowMenu(false);
                    document.getElementById("navMenu").style.display = "none";
                }
            }
        };

        window.addEventListener("resize", handleResize);
    });

    const [logo, setLogo] = useState(<div></div>);
    useLayoutEffect(() => {
        if (window?.location?.href && window.location.href.includes("app.dop.omnitracs.com"))
        {
            setLogo(<img className="w-64 md:w-96" src={soleraLogo} alt="Solera Fleet Solutions, Omnitracs Logo" />);
        } else {
            setLogo(<a href="https://www.baxtersoftware.com.au/"><img className="w-64" src={baxterLogo} alt="Baxter, Our Namesake, a Chow, Logoized" /></a>);
        }
    }, []);
    

    if (!isLoggedIn) {
        return (
            <div className="print:hidden flex flex-row bg-black">
                <div className="mx-6 my-auto">{logo}</div>
                <div className="p-5 text-right bg-black flex-grow">
                    <Link to="/login" title="Login" className="text-white hover:bg-purple-300 hover:text-black p-2 rounded outline-none focus:outline-none text-xl">Login</Link>
                </div>
            </div>
        );
    } else {
        return (
            <div ref={menuRef} className="print:hidden flex flex-row bg-black">
                <div className="mx-6 my-auto">{logo}</div>
                <div className="p-5 text-right bg-black flex-grow whitespace-nowrap">
                    <button onClick={toggleMenu} className="inline-block md:hidden w-8 h-8 bg-purple-600 text-white p-1">
                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                    </button>
                    <nav id="navMenu" className="hidden md:block absolute justify-end md:relative top-16 left-0 md:top-0 z-20 flex flex-col md:flex-row md:space-x-3 w-full md:w-auto bg-black shadow-md rounded-lg md:rounded-none md:shadow-none md:bg-transparent pb-2">
                        {userCanProvisionProcesses && <Link data-test-id="processes-link" to="/processes" onClick={toggleMenu} title="Processes" className="block md:inline text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Processes</Link>}
                        {userCanProvisionResources && <Link data-test-id="resources-link" to="/resources" onClick={toggleMenu} title="Resources" className="block md:inline text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Resources</Link>}
                        {userCanExecuteManualProcesses && <Link data-test-id="execute-link" to="/execute" onClick={toggleMenu} title="Execute" className="block md:inline text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Execute</Link>}
                        {userHasFilesAccess && <Link data-test-id="files-link" to="/files" onClick={toggleMenu} title="Files" className="block md:inline text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Files</Link>}
                        {userCanProvisionProcesses && Array.isArray(settingLinks) && settingLinks.length > 0 && <Link data-test-id="settings-link" to={settingLinks[0].path} onClick={toggleMenu} title="Settings" className="block md:inline text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Settings</Link>}
                        {userIsAdmin && Array.isArray(adminLinks) && adminLinks.length > 0 && <Link to={adminLinks[0].path} onClick={toggleMenu} title="Administration" className="block md:hidden text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Administration</Link>}
                        {userHasLogsAccess && <Link to="/logs" onClick={toggleMenu} title="Logs" className="block md:hidden text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Logs</Link>}
                        {userCanProvisionProcesses && <Link to="/processtracking/search" onClick={toggleMenu} title="Process Tracking" className="block md:hidden text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Process Tracking</Link>}
                        <Link to="/profile" onClick={toggleMenu} title="Profile" className="block md:hidden text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Profile</Link>
                        <a href="/logout" onClick={handleSignOutButton} title="Logout" className="block md:hidden text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">Logout</a>
                        <div title="Account" onClick={toggleAccountMenu} className="hidden md:inline relative text-white hover:bg-purple-300 hover:text-black p-2 pr-5 md:pr-2 rounded outline-none focus:outline-none text-xl">
                            <FontAwesomeIcon icon={faUserCircle}/>
                            <div id="accountDropDown" className="hidden absolute right-0 -ml-40 mt-5 z-50 bg-black rounded-lg">
                                {userIsAdmin && Array.isArray(adminLinks) && adminLinks.length > 0 && <Link to={adminLinks[0].path} onClick={toggleMenu} title="Administration" className="block text-white hover:bg-purple-300 hover:text-black m-2 p-2 rounded outline-none focus:outline-none text-xl">Administration</Link>}
                                {userHasLogsAccess && <Link to="/logs" onClick={toggleMenu} title="Logs" className="block text-white hover:bg-purple-300 hover:text-black m-2 p-2 rounded outline-none focus:outline-none text-xl">Logs</Link>}
                                {userCanProvisionProcesses && <Link to="/processtracking/search" onClick={toggleMenu} title="Process Tracking" className="block text-white hover:bg-purple-300 hover:text-black m-2 p-2 rounded outline-none focus:outline-none text-xl">Process Tracking</Link>}
                                <Link to="/profile" title="Profile" className="block text-white hover:bg-purple-300 hover:text-black m-2 p-2 rounded outline-none focus:outline-none text-xl">Profile</Link>
                                <a href="/logout" onClick={handleSignOutButton} title="Logout" className="block text-right w-auto text-white hover:bg-purple-300 hover:text-black m-2 p-2 rounded outline-none focus:outline-none text-xl">Logout</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}