import { useLayoutEffect } from "react";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorTile from "../../Common/ErrorTile";
import DataSelectorFieldContainer from "../DataSelectorFieldContainer";
import { useGetExternalDataQuery } from "../../../services/mip";
import {
    geValuesApiUrl,
    flattenConvertJsonData,
    ImportDataSelectorEntityPropertyUserEnteredDataDefaults,
    processDataSelectorChanges
} from "../../../midgard.js";

export default function DataSelector(props) {
    // the source options came from previously set externalData on the "related import file layout selector".
    // these are used in the ImportDataSelectorFieldContainer to show what fields have not already been mapped
    // and in the ImportDataSelectorField to let the user choose a field.
    let columnsInDataFile = [];
    if (Object.keys(props.formState).includes(props.field.FileLayoutSelectorProvisioningIdentifier)) {
        if (props.formState[props.field.FileLayoutSelectorProvisioningIdentifier].externalData.length > 0) {
            const flattenedData = flattenConvertJsonData(null, props.formState[props.field.FileLayoutSelectorProvisioningIdentifier].externalData[0]);
            columnsInDataFile = Object.keys(flattenedData);
        }
    }

    // if we are mapping a fixed width file, we want to not show, but preserve the index of, any columns that were not explicitly defined
    // from the list of "columnsInDataFile" that is used to allow selecting fields to map.
    const fixedWidthUnmappedIndexes = [];
    if (props.field.FileLayoutSelectorProvisioningIdentifier) {
        const columnSelectorIdentifier = props.formState[props.field.FileLayoutSelectorProvisioningIdentifier].field.ConvertToJsonApiQueryStringParameters["fixedWidthColumnsSelector"];
        // column defintions will be an empty array if it not fixed width.
        const columnDefinitions = [...props.formState[columnSelectorIdentifier].value].sort((a, b) => (parseInt(a.StartIndex) > parseInt(b.StartIndex) ? 1 : (parseInt(b.StartIndex) > parseInt(a.StartIndex) ? -1 : 0)));
        if (Array.isArray(columnDefinitions) && columnDefinitions.length > 0) {
            let allColumnsIndex = 0;
            if (parseInt(columnDefinitions[0].StartIndex) !== 0) {
                // the first mapped column is not at 0, so there will be an unampped column at index 0.
                fixedWidthUnmappedIndexes.push(0);
                allColumnsIndex++;
            }
            for (const definitionIndex in columnDefinitions) {
                const nextDefinitionIndex = parseInt(definitionIndex) + 1;
                allColumnsIndex++;
                const currentDefinitionEndColumn = parseInt(columnDefinitions[definitionIndex].StartIndex) + parseInt(columnDefinitions[definitionIndex].Length);
                if (columnDefinitions[nextDefinitionIndex] && parseInt(columnDefinitions[nextDefinitionIndex].StartIndex) !== currentDefinitionEndColumn) {
                    // the next column does not begin at the current columns end, so there is an unmapped column
                    // at the next index
                    fixedWidthUnmappedIndexes.push(allColumnsIndex);
                    allColumnsIndex++;
                }
            }
            if (columnsInDataFile.length > allColumnsIndex) {
                // if the columnsInDataFile has one more object that we have found 
                // then there is an unmapped column at the end.
                fixedWidthUnmappedIndexes.push(allColumnsIndex);
            }
        }
    }

    const dataSelectorExternalDataUrl = geValuesApiUrl(props.field, props.formState);

    const {
        data: entityPropertiesData,
        error: dataSelectorDataAPIError,
        isFetching: dataSelectorDataFetching,
    } = useGetExternalDataQuery(dataSelectorExternalDataUrl);

    // I had a note here that said this useLayoutEffect would only fire twice, on render and 
    // when the entityPropertiesData is updated, since that is the only dependency that can change.
    // but, this fires on every change to formState. I think I meant that processDataSelectorChanges
    // only does something in those cases, but I don't think that's true any more either. This
    // processDataSelectorChanges does primarily do things when the externalData from the API has changed
    // and it only updates formState if something important has changed. But it does fire on every change.
    const {formStateDispatch} = props;
    useLayoutEffect(() => {
        if (entityPropertiesData !== null && entityPropertiesData !== undefined) {
            processDataSelectorChanges(formStateDispatch, props.formState, props.field, entityPropertiesData);
        }
    }, [entityPropertiesData, formStateDispatch, props.formState, props.field]);



    const addUserEnteredDataToProperty = (propertyName, addLinkedProperties = true) => {
        const formStateValueCopy = [...props.formState[props.field.Parameter.Identifier].value];
        let currentMaxIndex = -1;
        for (const field of formStateValueCopy) {
            if (field.displayIndex > currentMaxIndex) {
                currentMaxIndex = field.displayIndex;
            }
        }
        for (const field of formStateValueCopy) {
            if (field.Name === propertyName) {
                if (field.displayIndex === -1) {
                    field.displayIndex = currentMaxIndex + 1;
                }
                if (!field.userEnteredData) {
                    field.userEnteredData = [];
                }
                field.userEnteredData.push(
                    {
                        ...ImportDataSelectorEntityPropertyUserEnteredDataDefaults, 
                        ...{ source: field.HasRegionDefault ? "RegionDefault" : "DataFile" },
                        ...{ lookupEntityKeyByIdentifier: !field.HasRegionDefault && field.AllowIdentifierLookup }
                    });
                if (addLinkedProperties && Array.isArray(field.LinkedPropertyNames)) {
                    for (const linkedPropertyName of field.LinkedPropertyNames) {
                        addUserEnteredDataToProperty(linkedPropertyName, false);
                    }
                }
                break;
            }
        }
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: formStateValueCopy}});
    };

    const removeUserEnteredDataFromProperty = (propertyName, index, removeLinkedProperties = true) => {
        const formStateValueCopy = [...props.formState[props.field.Parameter.Identifier].value];
        for (const field of formStateValueCopy) {
            if (field.Name === propertyName) {
                if (field.userEnteredData && field.userEnteredData.length > 1) {
                    field.userEnteredData.splice(index, 1);
                } else {
                    field.displayIndex = -1;
                    field.userEnteredData = undefined;
                }
                if (removeLinkedProperties && Array.isArray(field.LinkedPropertyNames)) {
                    for (const linkedPropertyName of field.LinkedPropertyNames) {
                        removeUserEnteredDataFromProperty(linkedPropertyName, index, false);
                    }
                }
                break;
            }
        }
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: formStateValueCopy}});
    };

    const setFieldValue = (propertyName, index, key, value) => {
        // formState.value will contain an array of fields to map.
        const formStateValueCopy = [...props.formState[props.field.Parameter.Identifier].value];
        for (const field of formStateValueCopy) {
            if (field.Name === propertyName) {
                if (key === "constantValue" && Array.isArray(field.ValidConstantValuesOnImport) && field.AllowMultipleValidConstantValuesOnImport === true) {
                    const selectedOptions = [];
                    for (const option of value) {
                        if (option.selected) {
                            selectedOptions.push(option.value);
                        }
                    }
                    field.userEnteredData[index][key] = selectedOptions;
                } else {
                    field.userEnteredData[index][key] = value;
                }

                // some key/values also need to clear/reset others. There may be a better way of doing this
                // i.e. using useReducer again just within this component, but for now if/else logic here
                // will do the trick.

                // when formatOption is set to None, reset related format fields to default
                if (key === "formatOption" && value === "None") {
                    // this is not the default for formatTypeCode but rather matches
                    // the type of the destination field.
                    field.userEnteredData[index]["formatTypeCode"] = field.JsonType;
                    field.userEnteredData[index]["formatString"] = "";
                    field.userEnteredData[index]["formatStringFunctions"] = [];
                    field.userEnteredData[index]["parseString"] = "";
                    field.userEnteredData[index]["sourceTimeZoneId"] = "";
                    field.userEnteredData[index]["destinationTimeZoneId"] = "";
                } else if (key === "formatOption" && value === "Format") {
                    // when we switch to format only, the parsestring should get reset
                    // we also reset the format string to account for differences between
                    // format and parsethenformat
                    // this is not the default for formatTypeCode but rather matches
                    // the type of the destination field.
                    field.userEnteredData[index]["formatTypeCode"] = field.JsonType;
                    field.userEnteredData[index]["parseString"] = "";
                    field.userEnteredData[index]["formatString"] = "";
                    field.userEnteredData[index]["formatStringFunctions"] = [];
                } else if (key === "formatOption") {
                    // if we are changing the format option and it hasn't already been caught
                    // by the None or Format options above, then we are changing to
                    // Parse or ParseThenFormat in which case we leave Parse alone
                    // and only zero out the format string.
                    // this is not the default for formatTypeCode but rather matches
                    // the type of the destination field.
                    field.userEnteredData[index]["formatTypeCode"] = field.JsonType;
                    field.userEnteredData[index]["formatString"] = "";
                    field.userEnteredData[index]["formatStringFunctions"] = [];
                } else if (key === "applyCalculation" && value === false) {
                    field.userEnteredData[index]["calculations"] = [];
                } else if (key === "applyCalculation" && value === true) {
                    field.userEnteredData[index]["calculations"] = [{
                        mathOperation: "Add",
                        source: "Constant",
                        mathOperand: "0",
                        roundOperation: "" // none
                    }];
                } else if (key === "applyStringConcatenations" && value === false) {
                    field.userEnteredData[index]["concatenations"] = [];
                } else if (key === "applyStringConcatenations" && value === true) {
                    field.userEnteredData[index]["concatenations"] = [{
                        source: "Constant",
                        value: ""
                    }];
                } else if (key === "useLookupEntityKeyFallback" && value === false) {
                    field.userEnteredData[index]["lookupEntityKeyFallback"] = "0";
                } else if (key === "useDataMaps" && value === false) {
                    field.userEnteredData[index]["dataMapName"] = "";
                } else if (key === "source" && value === "DataFile") {
                    // DataFile's don't have Constant Values
                    field.userEnteredData[index]["constantValue"] = "";
                } else if (key === "lookupEntityKeyByIdentifier" && value === false) {
                    field.userEnteredData[index]["lookupCacheFilename"] = "";
                    field.userEnteredData[index]["lookupEntityKeyFallback"] = "0";
                } else if (key === "urlSubstitution" && value === false) {
                    field.userEnteredData[index]["urlSubstitutionTTL"] = "";
                    field.userEnteredData[index]["urlSubstitutionKeyLength"] = "";
                } else if (key === "source" && value === "Constant") {
                    // If the constant has a single select of constant values set the default
                    if (Array.isArray(field.ValidConstantValuesOnImport) && field.ValidConstantValuesOnImport.length > 0 && field.ValidConstantValuesOnImport[0].Key !== undefined) {
                        if (field.AllowMultipleValidConstantValuesOnImport === true) {
                            field.userEnteredData[index]["constantValue"] = [field.ValidConstantValuesOnImport[0].Value];
                        } else {
                            field.userEnteredData[index]["constantValue"] = field.ValidConstantValuesOnImport[0].Value;
                        }
                    }
                } else if (key === "source" && value === "RegionDefault") {
                    // Region Defaults don't have format options
                    field.userEnteredData[index]["constantValue"] = "";
                    field.userEnteredData[index]["formatOption"] = "None";
                    field.userEnteredData[index]["parseString"] = "";
                    field.userEnteredData[index]["formatTypeCode"] = "String";
                    field.userEnteredData[index]["formatString"] = "";
                    field.userEnteredData[index]["formatStringFunctions"] = [];
                    field.userEnteredData[index]["sourceTimeZoneId"] = "";
                    field.userEnteredData[index]["destinationTimeZoneId"] = "";
                    field.userEnteredData[index]["applyCalculation"] = false;
                    field.userEnteredData[index]["calculations"] = [];
                    field.userEnteredData[index]["applyStringConcatenations"] = false;
                    field.userEnteredData[index]["concatenations"] = [];
                    field.userEnteredData[index]["concatenateDelimiter"] = ",";
                    field.userEnteredData[index]["lookupEntityKeyByIdentifier"] = false;
                    field.userEnteredData[index]["useLookupEntityKeyFallback"] = false;
                    field.userEnteredData[index]["lookupEntityKeyFallback"] = "0";
                    field.userEnteredData[index]["useDataMaps"] = false;
                    field.userEnteredData[index]["dataMapName"] = "";
                    field.userEnteredData[index]["urlSubstitution"] = false;
                    field.userEnteredData[index]["urlSubstitutionTTL"] = "";
                    field.userEnteredData[index]["urlSubstitutionKeyLength"] = "";
                } else if (key === "formatTypeCode" && value !== "DateTime") {
                    // when we change to something that is not a datetime, unset the timezoneId
                    field.userEnteredData[index]["sourceTimeZoneId"] = "";
                    field.userEnteredData[index]["destinationTimeZoneId"] = "";
                } else if (key === "customPropertyKey") {
                    // on custom property key changes unset the previous value regardless so
                    // that the warning that the key is not mapped to the orignal value goes away
                    field.userEnteredData[index]["previousCustomPropertyKey"] = null;
                }
                break;
            }
        }
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: formStateValueCopy}});
    };

    const thisComponentIsFetching = props.formState[props.field.Parameter.Identifier].isFetching;
    useLayoutEffect(() => {
        if (thisComponentIsFetching !== dataSelectorDataFetching) {
            formStateDispatch({type: "setIsFetching", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: dataSelectorDataFetching}});
        }
    }, [formStateDispatch, thisComponentIsFetching, dataSelectorDataFetching, props.field.Parameter.Identifier]);

    return (
        dataSelectorDataAPIError
            ? <ErrorTile message={dataSelectorDataAPIError} />
            : dataSelectorDataFetching
                ?   <LoadingSpinner text="Loading Data Selector" />
                :   <DataSelectorFieldContainer
                        field={props.field}
                        onAddFieldClick={addUserEnteredDataToProperty}
                        onDeleteClick={removeUserEnteredDataFromProperty}
                        setFieldValue={setFieldValue}
                        formState={props.formState}
                        columnsInDataFile={columnsInDataFile}
                        fixedWidthUnmappedIndexes={fixedWidthUnmappedIndexes}
                    />
    );
}