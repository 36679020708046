import { useState, useLayoutEffect, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSpinner } from "@fortawesome/free-solid-svg-icons";

import {
    useGetSettingsQuery,
    useUpdateGeneralSettingsMutation
} from "../../../services/mip";

export default function GeneralSettings(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Admin") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - General";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: settingsData,
        error: settingsError,
        isFetching: settingsIsFetching
    } = useGetSettingsQuery(null, { skip: !isLoggedIn || !hasPrivilege });

    const [selectedLogLevel, setSelectedLogLevel] = useState();
    const [publicApiThrottleSeconds, setPublicApiThrottleSeconds] = useState("60");
    const [publicApiGetJobThrottleSeconds, setPublicApiGetJobThrottleSeconds] = useState("60");
    const [maintenanceMode, setMaintenanceMode] = useState();
    const [successMessage, setSuccessMessage] = useState(null);
    const [validationMessage, setValidationmessage] = useState(null);
    const [submittingToAPI, setSubmittingToAPI] = useState(false);
    

    useLayoutEffect(() => {
        if (settingsData) {
            setSelectedLogLevel(settingsData.LogLevel ?? "Debug");
            setPublicApiThrottleSeconds(settingsData.PublicApiThrottleSeconds ?? "60");
            setPublicApiGetJobThrottleSeconds(settingsData.PublicApiGetJobThrottleSeconds ?? "60");
            setMaintenanceMode(settingsData.MaintenanceMode === true);
        }
    }, [settingsData]);

    const [updateSettings] = useUpdateGeneralSettingsMutation();
    const patchUpdate = async () => {
        setSubmittingToAPI(true);
        setValidationmessage(null);
        setSuccessMessage(null);
        const validationErrors = [];
        if (!Number.isInteger(parseInt(publicApiThrottleSeconds))) {
            validationErrors.push("Enter a valid number for API Throttle Seconds");
        }
        if (!Number.isInteger(parseInt(publicApiGetJobThrottleSeconds))) {
            validationErrors.push("Enter a valid number for API GET /job Throttle Seconds");
        }

        if (validationErrors.length !== 0) {
            setValidationmessage(validationErrors);
        } else {
            const dataToPost = {
                "LogLevel": selectedLogLevel,
                "MaintenanceMode": maintenanceMode === true,
                "PublicApiThrottleSeconds": parseInt(publicApiThrottleSeconds),
                "PublicApiGetJobThrottleSeconds": parseInt(publicApiGetJobThrottleSeconds)
            };
            try {
                await updateSettings(dataToPost).unwrap();
                setSuccessMessage("Settings updated.");
            } catch (err) {
                setValidationmessage(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
            }
        }
        setSubmittingToAPI(false);
    };

    useEffect(() => {
        if (settingsError && settingsError.status === 401) {
            window.location.href="/login";
        }
    }, [settingsError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="general" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faCog} /> General Settings</div>
                    </div>
                    {
                        validationMessage
                            ? <ErrorTile message={validationMessage} />
                            : null
                    }
                    {
                        successMessage
                            ? <div className="border text-center border-green-500 rounded-lg mt-2 mb-4 p-4 bg-green-200">{successMessage}</div>
                            : null
                    }
                    {
                        settingsError
                            ? <ErrorTile message={settingsError} />
                            : settingsIsFetching
                                ?   <LoadingSpinner text={"Loading Settings"} />
                                :   <>
                                    <div className="mb-3">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="selectedLogLevel">
                                            Log Level
                                        </label>
                                        <select name="LogLevel" id="LogLevel" value={selectedLogLevel} onChange={(e) => {setSelectedLogLevel(e.target.value);}} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                            <option value="Debug">Debug</option>
                                            <option value="Info">Info</option>
                                            <option value="Warn">Warn</option>
                                            <option value="Error">Error</option>
                                            <option value="Fatal">Fatal</option>
                                        </select>
                                    </div>
                                    <div className="my-6">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="publicApiThrottleSeconds">
                                            Public API Throttle Seconds
                                        </label>
                                        <input type="text" id="publicApiThrottleSeconds" name="publicApiThrottleSeconds" value={publicApiThrottleSeconds ?? ""} onChange={(e) => setPublicApiThrottleSeconds(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                    </div>
                                    <div className="my-6">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="publicApiGetJobThrottleSeconds">
                                            Public API GET /job Throttle Seconds
                                        </label>
                                        <input type="text" id="publicApiGetJobThrottleSeconds" name="publicApiGetJobThrottleSeconds" value={publicApiGetJobThrottleSeconds ?? ""} onChange={(e) => setPublicApiGetJobThrottleSeconds(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                    </div>
                                    <div className="my-6">
                                        <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="maintenanceMode">
                                            <input type="checkbox" value={maintenanceMode || false} name="maintenanceMode" id="maintenanceMode" checked={maintenanceMode ? "checked" : ""} onChange={(e) => {setMaintenanceMode(e.target.checked);}} className="mr-2 align-middle" />
                                            <span className="text-grey-800 mb-4 align-middle">Maintenance Mode</span>
                                        </label>
                                    </div>
                                    <div className="flex justify-evenly">
                                        <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={patchUpdate}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Update Settings"}</button>
                                    </div>
                                    </>
                    }
                </div>
            </div>
        </div>
        </>
    );
}