import React, { useLayoutEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "./features/auth/authSlice";
import { Amplify, Auth } from "aws-amplify";
import { cognitoConfig } from "./midgard.js";

// components
import ErrorTile from "./components/Common/ErrorTile";
import LoadingSpinner from "./components/Common/LoadingSpinner";
import Menu from "./components/Common/Menu";
import NotFound from "./components/Common/NotFound";
import Footer from "./components/Common/Footer";

// containers
//import Home from './containers/Home';
//import Dashboard  from './containers/Dashboard';
import Login from "./containers/Login";
import Execute from "./containers/Execute";
import Resources from "./containers/Resources";
import Processes from "./containers/Processes";
import ManualProcessForm from "./containers/ManualProcessForm";
import Profile from "./containers/Profile";
import DataMaps from "./containers/Settings/DataMaps";
import DataMapsForm from "./containers/Settings/DataMapsForm";
import EmailDistributionLists from "./containers/Settings/EmailDistributionLists";
import EmailDistributionListsForm from "./containers/Settings/EmailDistributionListsForm";
import TaskResultActions from "./containers/Settings/TaskResultActions";
import TaskResultActionsForm from "./containers/Settings/TaskResultActionsForm";
import OrderReservationWindows from "./containers/Settings/OrderReservationWindows";
import OrderReservationWindowsForm from "./containers/Settings/OrderReservationWindowsForm";
import ApiMap from "./containers/Settings/ApiMap";
import ApiKeys from "./containers/Settings/ApiKeys";
import ApiKeysForm from "./containers/Settings/ApiKeysForm";
import GeneralSettings from "./containers/Settings/GeneralSettings";
import ExtendedSettings from "./containers/Settings/ExtendedSettings";
import ExternalEntities from "./containers/Settings/ExternalEntities";
import ExternalEntitiesForm from "./containers/Settings/ExternalEntitiesForm";
import ExternalEntitiesSystems from "./containers/Settings/ExternalEntitiesSystems";
import ExternalEntitiesSystemsForm from "./containers/Settings/ExternalEntitiesSystemsForm";
import KillTasks from "./containers/Settings/KillTasks";
import UrlSubstitutions from "./containers/Settings/UrlSubstitutions";
import UrlSubstitutionsForm from "./containers/Settings/UrlSubstitutionsForm";
import Customers from "./containers/Admin/Customers";
import CustomersForm from "./containers/Admin/CustomersForm";
import Users from "./containers/Admin/Users";
import UsersForm from "./containers/Admin/UsersForm";
import Logs from "./containers/Logs";
import Files from "./containers/Files";
import ProcessTracking from "./containers/ProcessTracking";
import SeedMetadata from "./containers/SeedMetadata";
import ProvisionableItemIndex from "./containers/ProvisionableItemIndex";
import {
    mipApi,
    useGetProfileQuery
} from "./services/mip";

Amplify.configure({
    Auth: {
        storage: sessionStorage,
        region: cognitoConfig.awsRegion,
        userPoolId: cognitoConfig.awsUserPoolId,
        userPoolWebClientId: cognitoConfig.awsUserPoolWebClientId
    }
});

export default function App() {
    const [refreshRequired, setRefreshRequired] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        const loadInitialAuthStatus = async () => {
            try {
                // currentSession() will refresh token if needed.
                await Auth.currentSession();
                const userInfo = await Auth.currentUserInfo();
                dispatch({
                    type: "auth/login",
                    payload: userInfo
                });
            }
            catch(e) {
                if (process.env.REACT_APP_ENV === "test" && e !== "No current user" && e !== "Refresh Token has expired") {
                    // trying to debut what we get back here. It's rare.
                    console.log("onload returned something new:", e);
                }
                dispatch(mipApi.util.resetApiState());
                dispatch({type: "auth/logout"});
                if (!window.location.href.endsWith("/login")) {
                    setRefreshRequired(true);
                }
            }
            setIsAuthenticating(false);
        };
        loadInitialAuthStatus();
    }, [dispatch]); // end of useEffect

    const isLoggedIn = useSelector(selectIsLoggedIn);
    useLayoutEffect(() => {
        // if we we are done authenticating, and we are not logged in,
        // and we are not on the login page, then redirect to the login page.
        if (!isAuthenticating && !isLoggedIn && !window.location.href.endsWith("login")) {
            window.location.href = "/login";
        }
        setRefreshRequired(false);
    }, [isAuthenticating, isLoggedIn]);

    const {
        data: profileData,
        error: profileError,
        isFetching: profileIsFetching
    } = useGetProfileQuery(null, { skip: !isLoggedIn});

    useLayoutEffect(() => {
        if (!isAuthenticating && profileData && profileData.Data) {
            dispatch({
                type: "auth/updateProfile",
                payload: profileData.Data
            });
        }
    }, [isAuthenticating, profileData, dispatch]);

    useLayoutEffect(() => {
        if (profileError && profileError.status === 401) {
            window.location.href="/login";
        }
    }, [profileError]);

    const loginSecondsLeftTimer = useRef();
    useLayoutEffect(() => {
        const updateLoginSecondsLeft = () => {
            const loginDateTime = localStorage.getItem("loginDateTime");
            if (loginDateTime) {
                const loginExpires = parseInt(loginDateTime) + (1000 * 60 * 60 * 24); // 24 Hours
                const loginSecondsLeft = Math.round((loginExpires - new Date().getTime()) / 1000);
                dispatch({
                    type: "auth/updateLoginSecondsLeft",
                    payload: loginSecondsLeft > 0 ? Math.round(loginSecondsLeft) : 0
                });
                if (loginSecondsLeft > 0) {
                    if (loginSecondsLeft <= (60 * 10)) {
                        // less than 10 minutes left; Update every second for countdown timer.
                        loginSecondsLeftTimer.current = setTimeout(updateLoginSecondsLeft, 1000);
                    } else {
                        // more than 10 minutes left, set a timer for 10 mins left.
                        const thirtyMinsBeforeExpiration = (loginSecondsLeft - (60 * 10)) * 1000;
                        loginSecondsLeftTimer.current = setTimeout(updateLoginSecondsLeft, thirtyMinsBeforeExpiration);
                    }
                }
            }
        };
        if (isLoggedIn) {
            loginSecondsLeftTimer.current = updateLoginSecondsLeft();
        }
        return () => {
            if (loginSecondsLeftTimer.current) {
                clearTimeout(loginSecondsLeftTimer.current);
            }
        };
    }, [isLoggedIn, dispatch]);

    const RequireAuth = ({ children, redirectTo }) => (useSelector(selectIsLoggedIn) ? children : <Navigate to={redirectTo} />);

    const LoginRedirect = () => {
        const privilege = useSelector(selectPrivilege);
        const navigate = useNavigate();
        useLayoutEffect(() => {
            if (Array.isArray(privilege) && privilege.length > 0) {
                const hasProcessesPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");
                const hasExecutePrivilege = privilege.includes("ManualExecute") || privilege.includes("Deploy") || privilege.includes("All");
                const hasFilesPrivilege = privilege.includes("FileManagement") || privilege.includes("All");
                const redirectToPath = hasProcessesPrivilege
                    ? "/processes"
                    : hasExecutePrivilege
                        ? "/execute"
                        : hasFilesPrivilege
                            ? "/files"
                            : "/profile";
                navigate(redirectToPath);
            }
        }, [privilege, navigate]);
        return null;
    };

    return (
        refreshRequired
            ?   <LoadingSpinner text="Redirecting to Login" margin="mx-auto mt-8" />
            :   profileError
                    ? <ErrorTile message={profileError} />
                    : isAuthenticating || profileIsFetching
                        ?   <LoadingSpinner text="Loading Credentials" margin="mx-auto mt-8" />
                        :   <BrowserRouter>
                                <div className="pb-24 flex flex-col" style={{wordBreak: "break-word"}}>
                                    <Menu />
                                    <div className="flex-grow">
                                        <Routes>
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/login/redirect" element={<LoginRedirect />} />
                                            <Route path="/execute" element={<RequireAuth redirectTo="/login"><Execute /></RequireAuth>} />
                                            <Route path="/resources" element={<RequireAuth redirectTo="/login"><Resources /></RequireAuth>} />
                                            <Route path="/processes" element={<RequireAuth redirectTo="/login"><Processes /></RequireAuth>} />
                                            <Route path="/profile" element={<RequireAuth redirectTo="/login"><Profile /></RequireAuth>} />
                                            <Route path="/provisionableitem/:provisionableItemType/:provisionMode/:identifier" element={<RequireAuth redirectTo="/login"><ProvisionableItemIndex /></RequireAuth>} />
                                            <Route path="/manualprocess/:manualProcessIdentifier/edit" element={<RequireAuth redirectTo="/login"><ManualProcessForm /></RequireAuth>} />
                                            <Route path="/settings/data-maps" element={<RequireAuth redirectTo="/login"><DataMaps /></RequireAuth>} />
                                            <Route path="/settings/data-maps/:mapName" element={<RequireAuth redirectTo="/login"><DataMapsForm /></RequireAuth>} />
                                            <Route path="/settings/email-distribution-lists" element={<RequireAuth redirectTo="/login"><EmailDistributionLists /></RequireAuth>} />
                                            <Route path="/settings/email-distribution-lists/:listIdentifier" element={<RequireAuth redirectTo="/login"><EmailDistributionListsForm /></RequireAuth>} />
                                            <Route path="/settings/task-result-actions" element={<RequireAuth redirectTo="/login"><TaskResultActions /></RequireAuth>} />
                                            <Route path="/settings/task-result-actions/:taskResultActionIdentifier" element={<RequireAuth redirectTo="/login"><TaskResultActionsForm /></RequireAuth>} />
                                            <Route path="/settings/order-reservation-windows" element={<RequireAuth redirectTo="/login"><OrderReservationWindows /></RequireAuth>} />
                                            <Route path="/settings/order-reservation-windows/:windowId" element={<RequireAuth redirectTo="/login"><OrderReservationWindowsForm /></RequireAuth>} />
                                            <Route path="/settings/api-map" element={<RequireAuth redirectTo="/login"><ApiMap /></RequireAuth>} />
                                            <Route path="/settings/api-keys" element={<RequireAuth redirectTo="/login"><ApiKeys /></RequireAuth>} />
                                            <Route path="/settings/api-keys/:apiKeyId" element={<RequireAuth redirectTo="/login"><ApiKeysForm /></RequireAuth>} />
                                            <Route path="/settings/general" element={<RequireAuth redirectTo="/login"><GeneralSettings /></RequireAuth>} />
                                            <Route path="/settings/extended" element={<RequireAuth redirectTo="/login"><ExtendedSettings /></RequireAuth>} />
                                            <Route path="/settings/external-entities/systems" element={<RequireAuth redirectTo="/login"><ExternalEntitiesSystems /></RequireAuth>} />
                                            <Route path="/settings/external-entities/systems/:systemId" element={<RequireAuth redirectTo="/login"><ExternalEntitiesSystemsForm /></RequireAuth>} />
                                            <Route path="/settings/external-entities/:systemId" element={<RequireAuth redirectTo="/login"><ExternalEntities /></RequireAuth>} />
                                            <Route path="/settings/external-entities/:systemId/entity/:entityId" element={<RequireAuth redirectTo="/login"><ExternalEntitiesForm /></RequireAuth>} />
                                            <Route path="/settings/kill-tasks" element={<RequireAuth redirectTo="/login"><KillTasks /></RequireAuth>} />
                                            <Route path="/settings/url-substitutions" element={<RequireAuth redirectTo="/login"><UrlSubstitutions /></RequireAuth>} />
                                            <Route path="/settings/url-substitutions/new" element={<RequireAuth redirectTo="/login"><UrlSubstitutionsForm /></RequireAuth>} />
                                            <Route path="/admin/customers" element={<RequireAuth redirectTo="/login"><Customers /></RequireAuth>} />
                                            <Route path="/admin/customers/:customerIdentifier" element={<RequireAuth redirectTo="/login"><CustomersForm /></RequireAuth>} />
                                            <Route path="/admin/users" element={<RequireAuth redirectTo="/login"><Users /></RequireAuth>} />
                                            <Route path="/admin/users/:username" element={<RequireAuth redirectTo="/login"><UsersForm /></RequireAuth>} />
                                            <Route path="/logs" element={<RequireAuth redirectTo="/login"><Logs /></RequireAuth>} />
                                            <Route path="/files" element={<RequireAuth redirectTo="/login"><Files /></RequireAuth>} />
                                            <Route path="/processtracking/seed" element={<RequireAuth redirectTo="/login"><SeedMetadata /></RequireAuth>} />
                                            <Route path="/processtracking/search" element={<RequireAuth redirectTo="/login"><ProcessTracking /></RequireAuth>} />
                                            <Route path="/" element={<Navigate to="/processes" />} />
                                            <Route path="/settings" element={<Navigate to="/settings/data-maps" />} />
                                            <Route path="*" element={<NotFound />} />
                                        </Routes>
                                    </div>
                                    <Footer />
                                </div>
                            </BrowserRouter>
        );
    }