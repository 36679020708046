import LogsTableRow from "../LogsTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faClose
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";


export default function LogsTable(props) {

    const [lambdaFnFilter, setLambdaFnFilter] = useState(undefined);
    const [taskIdentifierFilter, setTaskIdentifierFilter] = useState(undefined);
    const [lambdaRequestIdentifierFilter, setLambdaRequestIdentifierFilter] = useState(undefined);
    const [taskChainInstanceFilter, setTaskChainInstanceFilter] = useState(undefined);

    const clearAllFilters = () => {
        setLambdaFnFilter(undefined);
        setTaskIdentifierFilter(undefined);
        setLambdaRequestIdentifierFilter(undefined);
        setTaskChainInstanceFilter(undefined);
    };

    // start by applying logLevel filter which is always on and special
    let logEntriesToDisplay = props.entries.filter((logEntry) => {
        let levelsThatPass;
        switch (props.logLevel) {
            case "INFO":
                levelsThatPass = ["INFO", "WARN", "ERROR", "FATAL"];
                break;
            case "WARN":
                levelsThatPass = ["WARN", "ERROR", "FATAL"];
                break;
            case "ERROR":
                levelsThatPass = ["ERROR", "FATAL"];
                break;
            case "FATAL":
                levelsThatPass = ["FATAL"];
                break;
            default:
                levelsThatPass = ["DEBUG", "INFO", "WARN", "ERROR", "FATAL"];
                break;
        }
        return levelsThatPass.includes(logEntry.LogLevel.toUpperCase());
    });
    if (lambdaFnFilter !== undefined) {
        logEntriesToDisplay = logEntriesToDisplay.filter((logEntry) => logEntry.LambdaFunctionName === lambdaFnFilter);
    }
    if (taskChainInstanceFilter !== undefined) {
        logEntriesToDisplay = logEntriesToDisplay.filter((logEntry) => logEntry.TaskChainInstance === taskChainInstanceFilter);
    }
    if (taskIdentifierFilter !== undefined) {
        logEntriesToDisplay = logEntriesToDisplay.filter((logEntry) => logEntry.TaskIdentifier === taskIdentifierFilter);
    }
    if (lambdaRequestIdentifierFilter !== undefined) {
        logEntriesToDisplay = logEntriesToDisplay.filter((logEntry) => logEntry.LambdaRequestIdentifier === lambdaRequestIdentifierFilter);
    }

    const logEntryCount = props.entries.length;
    const displayedEntriesCount = logEntriesToDisplay.length;

    const hasFilter = taskChainInstanceFilter || lambdaFnFilter || taskIdentifierFilter || lambdaRequestIdentifierFilter;

    return (
        <>
        <div className="text-sm text-right">Displaying {displayedEntriesCount} of {logEntryCount} log entries.</div>
        {
            hasFilter
                ?   <div className="border my-4 p-2">
                        <div className="font-bold">Applied Filters</div>
                        {
                            lambdaFnFilter !== undefined
                                ?   <div className="ml-4">
                                        <span className="hover:cursor-pointer text-red-500 pr-2"><FontAwesomeIcon icon={faClose} onClick={() => setLambdaFnFilter(undefined)} /></span>
                                        <span>Filtering on Lambda Function Name: {lambdaFnFilter}</span>
                                    </div>
                                : null
                        }
                        {
                            taskChainInstanceFilter !== undefined
                                ?   <div className="ml-4">
                                        <span className="hover:cursor-pointer text-red-500 pr-2"><FontAwesomeIcon icon={faClose} onClick={() => setTaskChainInstanceFilter(undefined)} /></span>
                                        <span>Filtering on Task Chain Identifier: {taskChainInstanceFilter}</span>
                                    </div>
                                : null
                        }
                        {
                            taskIdentifierFilter !== undefined
                                ?   <div className="ml-4">
                                        <span className="hover:cursor-pointer text-red-500 pr-2"><FontAwesomeIcon icon={faClose} onClick={() => setTaskIdentifierFilter(undefined)} /></span>
                                        <span>Filtering on Task Identifier: {taskIdentifierFilter}</span>
                                    </div>
                                : null
                        }
                        {
                            lambdaRequestIdentifierFilter !== undefined
                                ?   <div className="ml-4">
                                        <span className="hover:cursor-pointer text-red-500 pr-2"><FontAwesomeIcon icon={faClose} onClick={() => setLambdaRequestIdentifierFilter(undefined)} /></span>
                                        <span>Filtering on Lambda Request Identifier: {lambdaRequestIdentifierFilter}</span>
                                    </div>
                                : null
                        }
                        <div className="text-right"><span onClick={clearAllFilters} className="hover:cursor-pointer text-sm">Clear Filters</span></div>
                    </div>
                : null

        }
        <div className="grid" style={{gridTemplateColumns: "8rem 4rem 28rem auto"}}>
            <div className="text-center font-bold px-1 border whitespace-nowrap">
                <FontAwesomeIcon icon={faClock} /> { props.useUTC ? "UTC" : `UTC${ props.offset >= 0 ? "+" : "" }${ props.offset }` }
            </div>
            <div className="text-center font-bold px-1 border whitespace-nowrap">Level</div>
            <div className="text-center font-bold px-1 border whitespace-nowrap">Info</div>
            <div className="text-center font-bold px-1 border whitespace-nowrap">Message</div>
            {
                logEntriesToDisplay.map((logInfo) => (
                    <LogsTableRow
                        key={logInfo.Identifier}
                        entry={logInfo}
                        expandAll={props.expandAll}
                        useUTC={props.useUTC}
                        setTaskChainInstanceFilter={setTaskChainInstanceFilter}
                        setLambdaFnFilter={setLambdaFnFilter}
                        setTaskIdentifierFilter={setTaskIdentifierFilter}
                        setLambdaRequestIdentifierFilter={setLambdaRequestIdentifierFilter}
                        queryByLambdaRequestIdentifier={props.queryByLambdaRequestIdentifier}
                    />
                ))
            }
        </div>
        </>
    );
}