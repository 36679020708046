import { Auth } from "aws-amplify";
import { useEffect, useLayoutEffect, useState } from "react";
import Select, { createFilter } from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDoorOpen,
    faIdCard,
    faKey,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
    mipApi,
    useGetProfileQuery,
    useGetCustomersQuery,
    useUpdateCustomerIdentifierMutation
} from "../../services/mip";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";

export default function Profile(props) {   
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const canSwitchCustomers = privilege.includes("ManageCustomers") || privilege.includes("All");
    const [amplifyChangePasswordErrorMessage, setAmplifyChangePasswordErrorMessage] = useState(null);
    const [amplifyChangePasswordSuccess, setAmplifyChangePasswordSuccess] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [selectedCustomerIdentifier, setSelectedCustomerIdentifier] = useState();
    const [currentCustomer, setCurrentCustomer] = useState();

    const {
        data: profileData,
        error: profileError,
        isFetching: profileIsFetching
    } = useGetProfileQuery(null, { skip: !isLoggedIn || !canSwitchCustomers });

    const {
        data: customersData,
        error: customersError,
        isFetching: customersIsFetching
    } = useGetCustomersQuery(null, { skip: !isLoggedIn || !canSwitchCustomers });

    const [changeCustomerError, setChangeCustomerError] = useState();
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const customerOptions = [];
    if (Array.isArray(customersData?.Data) && profileData?.Data) {
        for (const customer of customersData.Data) {
            customerOptions.push({value: customer.Identifier, label: `${ customer.Name } (${ customer.GroupDisplayName })`});
        }
    }

    const [updateCustomerIdentifier] = useUpdateCustomerIdentifierMutation();
    const postCustomer = async (identifier) => {
        setIsRedirecting(true);
        setSubmittingToAPI(true);
        setChangeCustomerError(undefined);
        const objectToPost = {
            CustomerIdentifier: identifier
        };
        try {
            await updateCustomerIdentifier(objectToPost).unwrap();
            dispatch(mipApi.util.resetApiState());
            window.location.href = "/login/redirect";
        } catch (err) {
            setChangeCustomerError(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
            setIsRedirecting(false);
        }
        setSubmittingToAPI(false);
    };

    const handleChangePasswordFormSubmit = async (values) => {
        setAmplifyChangePasswordSuccess(null);
        setAmplifyChangePasswordSuccess(false);
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(cognitoUser, values.oldPassword, values.passwordConfirmation);
            setAmplifyChangePasswordSuccess(true);
        } catch (err) {
            setAmplifyChangePasswordErrorMessage(err.message);
        }
    };

    useEffect(() => {
        let redirectToLogin = false;
        if (profileError && profileError.status === 401) {
            redirectToLogin = true;
        }
        if (customersError && customersError.status === 401) {
            redirectToLogin = true;
        }
        if (redirectToLogin) {
            window.location.href="/login";
        }
    }, [
        profileError,
        customersError
    ]);

    useEffect(() => {
        document.title = "Profile - Change Password";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    useLayoutEffect(() => {
        if (Array.isArray(customersData?.Data) && profileData?.Data && currentCustomer === undefined) {
            for (const customer of customersData.Data) {
                if (customer.Identifier === profileData.Data.CustomerIdentifier) {
                    setCurrentCustomer({
                        value: customer.Identifier,
                        label: `${ customer.Name } (${ customer.GroupDisplayName })`
                    });
                    break;
                }
            }
        }
    }, [customersData, profileData, currentCustomer]);

    useLayoutEffect(() => {
        if (profileData?.Data && selectedCustomerIdentifier === undefined) {
            setSelectedCustomerIdentifier(profileData.Data.CustomerIdentifier);
        }
    }, [profileData, selectedCustomerIdentifier]);

    return (
        <>
        {
            isRedirecting
                ?   <LoadingSpinner text={"Redirecting"} />
                :   <>
                        {
                            canSwitchCustomers 
                                ?   <div className="mx-auto bg-white rounded-lg p-4 mb-4">
                                        <div className="text-2xl p-1 pb-4"><FontAwesomeIcon icon={faIdCard} /> Assigned Customer</div>
                                        {
                                            changeCustomerError && <ErrorTile message={changeCustomerError} />
                                        }
                                        {
                                        profileError || customersError
                                            ? <ErrorTile message={profileError || customersError} />
                                            : profileIsFetching || customersIsFetching || selectedCustomerIdentifier === undefined || currentCustomer === undefined
                                                ? <LoadingSpinner text={"Loading Customers"} />
                                                :   <div className="flex">
                                                        <Select className="flex-1" filterOption={createFilter({ ignoreAccents: false })} isSearchable={true} options={customerOptions} defaultValue={currentCustomer} onChange={(e) => setSelectedCustomerIdentifier(e.value)} />
                                                        <button disabled={submittingToAPI || !selectedCustomerIdentifier} className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded py-1 px-4 ml-1" type="button" onClick={() => postCustomer(selectedCustomerIdentifier)}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <FontAwesomeIcon icon={faDoorOpen} />}</button>
                                                    </div>
                                        }
                                    </div>
                                :   null
                        }
                        <div className="mx-auto bg-white rounded-lg p-4 mb-4">
                            <div className="text-2xl p-1 pb-4"><FontAwesomeIcon icon={faKey} /> Change Password</div>
                            <Formik
                                initialValues={{
                                    oldPassword: "",
                                    password: "",
                                    passwordConfirmation: ""
                                }}
                                onSubmit={handleChangePasswordFormSubmit}
                                validationSchema={Yup.object({
                                    oldPassword: Yup.string().required("Required"),
                                    password: Yup.string()
                                        .required("Required")
                                        .min(8, "Must be at least 8 characters")
                                        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/, "Must contain at least one of each: lower case letter, upper case letter, number, special character."),
                                    passwordConfirmation: Yup.string().required("Required").oneOf([Yup.ref("password"), null], "Passwords must match")
                                })}
                            >
                                <Form>
                                    <div className="my-3">
                                        <label htmlFor="oldPassword" className="block text-grey-800 text-sm font-bold mb-2">Current Password</label>
                                        <Field name="oldPassword" type="password" placeholder="αααααααα" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                                        <ErrorMessage name="oldPassword">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                                    </div>
                                    <div className="my-3">
                                        <label htmlFor="password" className="block text-grey-800 text-sm font-bold mb-2">New Password</label>
                                        <Field name="password" type="password" placeholder="αααααααα" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                                        <ErrorMessage name="password">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                                    </div>
                                    <div className="my-3">
                                        <label htmlFor="passwordConfirmation" className="block text-grey-800 text-sm font-bold mb-2">Confirm New Password</label>
                                        <Field name="passwordConfirmation" type="password" placeholder="αααααααα" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                                        <ErrorMessage name="passwordConfirmation">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                                    </div>
                                    {amplifyChangePasswordErrorMessage ? <div className="text-red-500 my-3">{amplifyChangePasswordErrorMessage}</div> : null }
                                    {amplifyChangePasswordSuccess ? <div className="text-green-400 my-3">Password changed.</div> : null}
                                    <div className="flex justify-evenly">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit" >Change Password</button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </>
        }
        </>
    );
}